<template>
  <div>
    <a-card title="拣货">
      <a-button slot="extra" type="primary" ghost @click="goBack"><a-icon type="left" />返回</a-button>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :loading="tableLoading"
          :columns="columns"
          :data-source="pickingMaterialItems"
          :pagination="false"
        >
          <div slot="picking_quantity" slot-scope="value, item, index">
            <a-input-number v-model="item.picking_quantity" :min="0" size="small"></a-input-number>
          </div>
          <template slot="sub_batch" slot-scope="value, item, index">
            <a-select v-model="item.sub_batch" placeholder="请输入或选择库位" size="small" style="width: 100%">
              <a-select-option
                v-for="inventoryItem in item.inventory_items.filter(inventory_item => !$route.query.area || $route.query.area == inventory_item.reservoir_area)"
                :key="inventoryItem.id"
                :value="inventoryItem.id"
                >{{ inventoryItem.location_number }} | 库存:{{ inventoryItem.remain_quantity }} | {{ inventoryItem.status_display }}</a-select-option
              >
            </a-select>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button type="danger" @click="removeMaterial(item, index)">删除</a-button>
              <a-button type="primary" @click="copyMaterial(item, index)">复制</a-button>
              <!-- <a-button @click="autoPicking(item, index)">自动拣货</a-button> -->
            </a-button-group>
          </div>
        </a-table>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="createLoading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { pickingCreateMaterials, pickingRecordCreate } from "@/api/stockOut";

export default {
  data() {
    return {
      tableLoading: false,
      createLoading: false,
      pickingOrderId: null,
      pickingMaterialItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "名称",
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: "数量",
          key: "picking_quantity",
          width: 80,
          scopedSlots: { customRender: "picking_quantity" },
        },
        {
          title: "应拣数量",
          dataIndex: "total_quantity",
          key: "total_quantity",
          width: 100,
        },
        {
          title: "剩余拣货数量",
          dataIndex: "remain_quantity",
          key: "remain_quantity",
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
          width: 60,
        },
        {
          title: "批次",
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: "库位",
          key: "sub_batch",
          width: 240,
          scopedSlots: { customRender: "sub_batch" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 190,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    initData() {
      this.pickingOrderId = this.$route.query.id;
      this.getPickingMaterials();
    },
    goBack() {
      this.$router.go(-1);
    },
    getPickingMaterials() {
      this.tableLoading = true;
      pickingCreateMaterials({ id: this.$route.query.id })
        .then((data) => {
          let pickingMaterialItems = [];
          for (let item of data) {
            if (item.remain_quantity > 0) {
              if (item.recommend_location_items.length > 0) {
                for (let recommend_location_item of item.recommend_location_items) {
                  pickingMaterialItems.push({
                    ...item,
                    sub_batch: recommend_location_item.sub_batch,
                    picking_quantity: recommend_location_item.remain_quantity,
                  });
                }
              } else {
                pickingMaterialItems.push({
                  ...item,
                  picking_quantity: 0,
                });
              }
            }
          }

          this.pickingMaterialItems = pickingMaterialItems;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    create() {
      if (this.pickingMaterialItems.length == 0) {
        this.$message.warn("没有产品");
        return;
      }

      for (let item of this.pickingMaterialItems) {
        if (!item.sub_batch) {
          this.$message.warn("未选择库位");
          return;
        }
      }

      let pickingMaterialItems = [];
      for (let item of this.pickingMaterialItems) {
        if (item.picking_quantity > 0 && item.sub_batch) {
          pickingMaterialItems.push({
            picking_material: item.id,
            sub_batch: item.sub_batch,
            total_quantity: item.picking_quantity,
          });
        }
      }

      this.createLoading = true;
      pickingRecordCreate({
        picking_order: this.pickingOrderId,
        picking_record_material_items: pickingMaterialItems,
      })
        .then((data) => {
          this.$message.success("创建成功");
          this.$router.go(-1);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    removeMaterial(item, index) {
      this.pickingMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.pickingMaterialItems.splice(index, 0, { ...item });
    },
    autoPicking(item, index) {
      let materialItems = [];
      let remainQuantity = item.picking_quantity;

      for (let subBatchItem of item.recommend_location_items) {
        if (remainQuantity > subBatchItem.remain_quantity) {
          materialItems.push({
            ...item,
            sub_batch: subBatchItem.id,
            location_number: subBatchItem.location_number,
            picking_quantity: subBatchItem.remain_quantity,
          });

          remainQuantity -= subBatchItem.remain_quantity;
        } else {
          materialItems.push({
            ...item,
            sub_batch: subBatchItem.id,
            location_number: subBatchItem.location_number,
            picking_quantity: remainQuantity,
          });

          break;
        }
      }
      this.pickingMaterialItems.splice(index, 1, ...materialItems);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped></style>
